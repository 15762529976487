import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import AgentHomePage from "./AgentHomePage";
import { CookieSetting } from "cookiesetting-component";

function App() {
  return (
    <>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/:agentName" element={<AgentHomePage />} />
      
    </Routes>
    <CookieSetting 
    privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK} 
    configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
    analytics={process.env.REACT_APP_ANALYTICS_PATH}
  />
  </>

  );
}

export default App;
