/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import "./App.css";
import "@fontsource/poppins";
import HeaderNavbar from "./components/Header/index.js";
import { Spinner } from "react-bootstrap";
import MediaQuery from "react-responsive";
import axios from "axios";
import { Helmet } from "react-helmet";
const RigvedQuotes = lazy(() => import("./components/RigvedQuotes/index.js"));
const Disclaimer = lazy(() => import("./components/Disclaimer/index.js"));
const Body = lazy(() => import("./components/Body/Body.js"));
const TruApproval = lazy(() =>
  import("./components/TruApprovalAngelAi/TruApproval.js")
);
const AngelAi = lazy(() =>
  import("./components/TruApprovalAngelAi/AngelAi.js")
);
const ProductSuite = lazy(() =>
  import("./components/ProductSuiteUltraFastSaving/ProductSuite.js")
);
const UltraFastSaving = lazy(() =>
  import("./components/ProductSuiteUltraFastSaving/UltraFastSaving.js")
);
const RealtorCustom = lazy(() =>
  import("./components/ProductSuiteUltraFastSaving/RealtorCustom.js")
);
const LoanOfficer = lazy(() =>
  import("./components/LoanOfficer/LoanOfficer.js")
);
const Footer = lazy(() => import("./components/CellFooter/index.js"));

function AgentHomePage() {
  const { agentName } = useParams();
 
  const [loading, setLoading] = useState(true);
  const [loadBodyData, setLoadBodyData] = useState(true);
  const [showRigVedQuotes, setShowRigVedQuotes] = useState(false);
  const [agentData, setAgentData] = useState();
  const [loanOfficerActive, setLoanOfficerActive] = useState(true);
  const [loanOfficerData, setLoanOfficerData] = useState();
  const [realorData, setRealtorData] = useState();
  const [aiBrandingData, setAiBrandingData] = useState();
  const [angelAiUserInfo, setAngelAiUserInfo] = useState();
  const [showOtherComponents, setShowOtherComponents] = useState(false);
  const canonicalUrl = agentName
  ? `https://www.angelai.com/agent/${agentName}/`
  : "https://www.angelai.com/agent/";
  
  const handleShowOtherComponents =  () => setShowOtherComponents(true);
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 1) {
        handleShowOtherComponents();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 07/20/2023
     * The below function is used to fetch the ai-branding.json data for AI Branding.
     * @returns JSON Object of the requested file
     */
    const fetchAiBrandingJson = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_ANGELAI_JSON)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setAiBrandingData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * Fetches the AngelAi user info from the specified endpoint.
     *
     * @return {Promise<void>} - A Promise that resolves when the user info has been fetched successfully.
     */
    const fetchAngelAiUserInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_ANGEL_AI_WEB_USER_INFO)
          .then((response) => {
            //  console.log(JSON.stringify(response.data));
            setAngelAiUserInfo(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * Checks if a loan officer is active.
     *
     * @param {string} loanOfficerId - The ID of the loan officer.
     * @return {Promise<void>} A promise that resolves when the function completes.
     */
    const checkifLoanOfficerIsActive = async (loanOfficerId) => {
      try {
        await axios
          .post(process.env.REACT_APP_CHECK_LO_ACTIVE, {
            loanOfficerId: loanOfficerId,
          })
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setLoanOfficerActive(response.data.active);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * @author Pratik Kajrolkar
     * @date 07/20/2023
     * The below function is used to check if agent details are available.
     * @returns JSON Object of the requested file
     */
    const fetchAgentDetails = async () => {
      try {
        const agentJsonName = agentName + ".json";
        await axios
          .get(
            process.env.REACT_APP_CDN +
              "realtor-cobranding/jsons/" +
              agentJsonName
          )
          .then(async (response) => {
            const responseData = response.data;
            //console.log("responseData: " + responseData);
            if (responseData) {
              setAgentData(responseData);
              await fetchAiBrandingJson();
              setLoading(false);
              let loanOfficerId;
              if (responseData.staff.loanOfficer) {
                loanOfficerId = responseData.staff.loanOfficer.ssid;
                await checkifLoanOfficerIsActive(loanOfficerId);
                setLoanOfficerData(responseData.staff.loanOfficer);
              } else {
                setLoanOfficerActive(false);
                setLoanOfficerData(null);
              }
              await fetchAngelAiUserInfo();
              setRealtorData(responseData.staff.realtor);
              setLoadBodyData(false);
            } else {
              window.location.replace(process.env.PUBLIC_URL);
            }
          })
          .catch((error) => {
            window.location.replace(process.env.PUBLIC_URL);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchAgentDetails();
  }, [agentName]);

  const CustomRealtorSection =
    agentName === "albertsafdie" ? (
      <Suspense fallback={<></>}>
        <RealtorCustom />
      </Suspense>
    ) : null;

  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      <div className="bodyContainer">
        {loading ? (
          <div className="mt-5 mb-3 text-center">
            <Spinner animation="border" />
            <h5>Loading...</h5>
          </div>
        ) : (
          <>
            {agentData && aiBrandingData ? (
              <>
                <HeaderNavbar
                 handleShowOtherComponents={handleShowOtherComponents}
                  aiBrandingData={aiBrandingData}
                  loanOfficerActive={loanOfficerActive}
                  loanOfficerData={loanOfficerData}
                  setShowRigVedQuotes={setShowRigVedQuotes}
                  agentData={agentData}
                />
                <Suspense fallback={<div />}>
                  <RigvedQuotes
                    showRigVedQuotes={showRigVedQuotes}
                    setShowRigVedQuotes={setShowRigVedQuotes}
                  />
                </Suspense>
                {loadBodyData ? (
                  <div className="mt-5 mb-3 text-center">
                    <Spinner animation="border" />
                    <h5>Loading...</h5>
                  </div>
                ) : (
                  <>
                    <Suspense fallback={<div />}>
                      <Body
                        realtorData={realorData}
                        loanOfficerActive={loanOfficerActive}
                        loanOfficerData={loanOfficerData}
                        angelAiUserInfo={angelAiUserInfo}
                      />
                    </Suspense>
                    {CustomRealtorSection}
                    <MediaQuery query="(min-width: 992px)">
                      <Suspense fallback={<div />}>
                        <TruApproval
                          realtorData={realorData}
                          loanOfficerActive={loanOfficerActive}
                          loanOfficerData={loanOfficerData}
                          angelAiUserInfo={angelAiUserInfo}
                        />
                      </Suspense>
                    </MediaQuery>

                    <MediaQuery query="(max-width: 991.7px)">
                      <Suspense fallback={<div />}>
                        <TruApproval
                          realtorData={realorData}
                          loanOfficerActive={loanOfficerActive}
                          loanOfficerData={loanOfficerData}
                          angelAiUserInfo={angelAiUserInfo}
                        />
                      </Suspense>
                    </MediaQuery>
                    {showOtherComponents && (
                      <>
                        <Suspense fallback={<div />}>
                          <AngelAi
                            aiBrandingData={aiBrandingData}
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            realtorData={realorData}
                          />
                        </Suspense>
                        <Suspense fallback={<div />}>
                          <ProductSuite />
                        </Suspense>
                        <Suspense fallback={<div />}>
                          <UltraFastSaving aiBrandingData={aiBrandingData} />
                        </Suspense>
                        <Suspense fallback={<div />}>
                          <LoanOfficer
                            loanOfficerActive={loanOfficerActive}
                            loanOfficerData={loanOfficerData}
                            realtorData={realorData}
                            angelAiUserInfo={angelAiUserInfo}
                            aiBrandingData={aiBrandingData}
                          />
                        </Suspense>
                        <Suspense fallback={<div />}>
                          <Disclaimer
                            realtorDisclaimer={
                              realorData ? realorData.disclaimer : ""
                            }
                            aiBrandingData={aiBrandingData}
                          />
                        </Suspense>
                        <Suspense fallback={<div />}>
                          <Footer aiBrandingData={aiBrandingData} />
                        </Suspense>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default AgentHomePage;